import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Loader } from 'react-feather';

// controllers
import { _metaMaskConnect, _connectionStatus } from '../controllers/MetaMask'



//
//
export default function PageHome(props){

    
    const connected = useSelector(state => state.auth.response);
    const [ isLoading, setLoading ] = React.useState(false)


    //
    //
    const _connect = async (option) => {
        setLoading(true)

        if(option==='metamask'){
            const connect = await _metaMaskConnect()
            if(connect){
                const getStatus = await _connectionStatus(connect.address, connect.provider)
                if(!getStatus.status){
                    setLoading(false)
                }else{
                    setLoading(false)
                }
            }else{
                setLoading(false)
            }
        }
    }

    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>siNgulariFTy</title>
            </Helmet>


                
                <div className="card dark bg-one bg-center">
                    <h2>Welcome to the Singularifty Platform</h2>
                    <p>
                        The Singularfty platform is a unique NFT marketplace, where anyone can find, claim and buy limited digital NFT tokens available to any collector. Discover and invest in the digital art assets of your choice. 
                        Don’t miss your opportunity to own something special, valuable and scares. Start collecting today!
                    </p>
                    {!connected.status && 
                        <button type="button" className='button secondary' onClick={()=> _connect('metamask')}>
                            <>{isLoading ? <><Loader size={20} className="animate-spin mr-2"/>Connecting...</> : <>Connect wallet</>}</>
                        </button>
                    }
                </div>


                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 my-8'>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col items-center justify-between'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-wallet text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Connect with MetaMask</h3>
                            <p className='text-center'>
                                Connect your crypto wallet with MetaMask for quick and easy interaction with the Ethereum blockchain.
                            </p>
                        </div>
                        <Link to={'/tutorial'} className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to use MetaMask</Link>
                    </div>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col items-center justify-between'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-parachute text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Claim NFT Tokens from Drop</h3>
                            <p className='text-center'>
                                Claim your NFT tokens from the Drop section and don't miss out on the limited collections of NFT art available only on the Singularifty platform.
                            </p>
                        </div>
                        <Link to={'/tutorial'} className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to Claim NFTs</Link>
                    </div>

                    <div className='rounded-3xl bg-gray-light p-10 flex flex-col items-center justify-between'>
                        <div className='flex flex-col items-center'>
                            <span className='icon-star text-4xl text-secondary'></span>
                            <h3 className='font-serif font-bold text-2xl text-center my-6'>Send and Receive NFT Tokens</h3>
                            <p className='text-center'>
                                Send and receive NFT collectable tokens directly from your wallet on the platform. Store them on separate wallets or send them as a gift. 
                            </p>
                        </div>
                        <Link to={'/tutorial'} className='label light alt mt-6 mx-auto'><span className='icon-help text-lg mr-2'></span>How to Transfer NFTs</Link>
                    </div>

                </div>



        </Fragment>
    );

}
