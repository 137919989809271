import React, { Fragment } from 'react'
import ProgressiveImage from "react-progressive-graceful-image"
import { Loader } from 'react-feather';


//
//
export default function NFTitem(props) {


    const [ state, setState ] = React.useState('inactive')
    const [ actionState, setActionState ] = React.useState('default')


    //
    //
    const _setStatus = () => {
        if(state==='inactive'){
            setState('active')
        }
        if(state==='active'){
            setState('inactive')
        }
        if(props.selected){
            setState('active')
        }
    }


    //
    //
    React.useEffect(()=>{

        if(props.selected){
            setActionState('selected')
            setState('active')
        }else{
            setActionState('default')
            setState('inactive')
        }

    },[props])


    //
    //
    return (
        <Fragment>
            <div className={`nftItem ${state}`}>
                <ProgressiveImage src={props.image} placeholder={require('../../assets/images/nft-placeholder.png')}>
                {(src, loading) => (
                    <div
                        style={{backgroundImage: `url(${src})`, opacity: loading ? 0.5 : 1}}
                        className={`ProgressiveImage ${loading ? 'transition' : 'transition'}`}
                    >
                        {loading && <span className="preload backdrop-blur-lg"><Loader size={26} className="animate-spin text-secondary"/></span>}
                    </div>
                )}
                </ProgressiveImage>
                <div className='overlay' onClick={()=>_setStatus()}>
                    <span>Author</span>
                    <p>{props.author}</p>
                    <div className='my-3 w-8 h-[2px] bg-secondary'></div>
                    <span>Collection</span>
                    <p>{props.collection}</p>
                    <div className='my-3 w-8 h-[2px] bg-secondary'></div>
                    <span>Token ID</span>
                    <p>{props.tokenId}</p>
                </div>
                {state==='active' && 
                <div className='action' onMouseEnter={()=>setActionState(props.selected ? 'selected' : 'hover')} onMouseLeave={()=>setActionState(props.selected ? 'selected' : 'default')} onClick={()=>props.action(props.tokenId)}>
                    {actionState==='default' && <span className='icon-checkbox'></span>}
                    {actionState==='hover' && <span className='icon-checkbox-hover'></span>}
                    {actionState==='selected' && <span className='icon-checkbox-selected'></span>}
                </div>
                }
            </div>
        </Fragment>
    );
};
