import {toast} from 'react-toastify'



//
//
export const _log = (title, message, type='log') => {
  if(process.env.REACT_APP_DEBUG==='true'){
    console[type](title, message)
  }
}



//
//
export const _timeout = function(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  });
}


//
//
export const _goBack = (location) => {
  if(location.state && location.state !== ''){
    return {pathname:location.state.pathname, state: location.state}
  }else{
    return false;
  }
}



//
//
export const inArray = (needle, haystack) => {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}


//
//
export const _toaster = (title=null, message=null, type) => {

  let cssClass = 'csBackground'+type.charAt(0).toUpperCase() + type.slice(1)

  toast[type](
    <>
        {title && <strong className="mb-2 block text-sm">{title}</strong>}
        {message && <p className="text-sm text-black text-opacity-75">{message}</p>}
    </>, 
    {className: cssClass}
  )

}


//
//
export const _isActivePage = (path, location) => {
  // const index  = (location.pathname).indexOf(path)
  // console.log('location.pathname',location.pathname, path, index)
  // if(index===0){
  if(location.pathname===path){
    return true
  }else{
    return false
  }
}


//
//
export const _shortenAddress = (address) => {
  return address.substr(0,6)+'...'+address.substr(-4)
}


//
//
export const _copy = (text, notification=true, message=false) => {

  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if(notification){
    _toaster('Copied', message, 'success')
  }
}