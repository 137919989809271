import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'


export default function Cookies(props){


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Cookies | siNgulariFTy</title>
      </Helmet>


            <div className="main justify-center landing">

              <div className="container py-5 typography">

                <h1>Cookies</h1>
                
                <h4>May 2022</h4>

                <p>
                  When you create or log in to an online account you agree to a cookie banner which provides a link to the Privacy and Cookies notice. Otherwise, by continuing to use our websites, content, products or services, you agree to the use of cookies as described in this notice.
                </p>
                <p>
                  You should be aware that when you access or use our content, products and services, we may collect information from the devices you use to receive Nexthash Group LTD content, products and services by using 'cookies'. We also collect such information from third party websites, mobile sites and from apps where we show our own adverts, using cookies and related ad technology.
                </p>
                <p>
                  If you'd like to learn how to manage these cookies and choose whether or not to receive information of different types, please see the section "Controlling My Cookies" below.
                </p>
                  <h4>What are cookies and how do they work?</h4>
                <p>
                  Cookies are small bits of text that are downloaded to the devices you use to receive Nexthash limited content, products and services and access online information. Your browser makes these cookies available every time you visit the website again, so it can recognise you and can then tailor what you see on your device.
                </p>
                <p>
                  <h4>What do you use cookies for?</h4>
                  Cookies are an important part of the internet. They make using devices and accessing online information much smoother and affect lots of the useful features of websites. There are many different uses for cookies, but they fall into four main groups.
                </p>
                  <h4>Cookies that are needed to provide the content, product or service you have asked for</h4>
                <p>
                  Some cookies are essential to help your devices download or stream the information, or so you can move around websites and use their features. Without these cookies, content, products or services you've asked for can't be provided.
                </p>
                <p>
                  Here are some examples of essential cookies:
                </p>
                <p>
                  <ul>
                    <li>Positioning information on a smartphone screen, tablet device or other screen so that you can see the website and use its functionality.</li>
                    <li>You will not open, or attempt to open, a Nexthash Account under any name except your own; or use your Nexthash Account to carry out transactions on behalf of a third party;</li>
                    <li>Keeping you logged in during your visit or enabling you to stream content. Without cookies, you might have to log in on every website you visit or repeatedly adjust your volume and viewing settings.</li>
                    <li>When you add something to the online shopping basket, cookies make sure it's still there when you get to the checkout.</li>
                    <li>Some are session cookies which make it possible to navigate through the website smoothly.</li>
                  </ul>
                </p>
                  <h4>Improving your browsing experience</h4>
                <p>
                  Cookies allow the application or website to remember choices you make, such as your language or region and they provide improved features.
                </p>
                <p>
                  Here are a few examples of just some of the ways that cookies are used to improve your experience on our applications and websites:
                </p>
                <p>
                  <ul>
                    <li>Remembering your preferences and settings, including marketing preferences, such as choosing whether you wish to receive marketing information. Remembering if you've filled in a survey, so you're not asked to do it again.</li>
                    <li>Remembering if you've been to the application or website before.</li>
                    <li>Restricting the number of times you're shown a particular advertisement. This is sometimes called 'frequency capping'.</li>
                    <li>Showing you information that's relevant to content, products or services that you receive.</li>
                    <li>Giving you access to content provided by social-media sites like Facebook or Twitter.</li>
                    <li>Showing 'related article' links that are relevant to the information you're looking at.</li>
                    <li>Remembering an application or website you've entered, such as weather forecasts.</li>
                  </ul>
                </p>
                  <h4>Analytics</h4>
                <p>
                  We like to keep track of what websites, information and links are popular and which ones don't get used so much, to help us keep our information relevant and up to date. It's also very useful to be able to identify trends of how people navigate (find their way through) our information and when and where 'error messages' may originate.
                </p>
                <p>
                  This group of cookies, often called 'analytics cookies' are used to gather this information. The information collected is grouped with the information from everyone else’s cookies. We can then see the overall patterns of usage rather than any one person’s activity. Analytics cookies are used to improve how an application, a website and its pages work.
                </p>
                <p>
                  Our applications, web locations, websites and communications you get from us contain small invisible images known as 'web beacons' or ' pixels'. These are used to manage the interaction between you and the online information or email and allow us to assess the effectiveness of the communication.
                </p>
                  <h4>How we show advertising and marketing that is relevant to your interests</h4>
                <p>
                  We sell space on some of our websites, mobile sites and apps to advertisers. The resulting adverts often contain cookies and related ad technology that is placed by third party partners such as ad servers, ad agencies, ad network providers and research firms. These partners use the browsing information collected from these cookies to:
                </p>
                <p>
                  Restrict the number of times you see the same ad (frequency capping) and
                </p>
                <p>
                  Help show other advertisements that are relevant to you while you're accessing our information. This information about your browsing activity may be grouped with information about what is being accessed by other users, into interest groups, and then used to show you advertisements based on those interests. This is often called online behavioural advertising (OBA). OBA is a way of using information about your web- browsing activity, collected by using cookies, to group you with other users into interest groups and show you advertisements based on those interests.
                </p>
                <p>
                  Sometimes our websites contain advertisements for our own NextHash Limited products. These advertisements use cookies in the same way as described above. We may also use cookies and related ad technology on third party websites, mobile sites and apps to show you OBA ads based on data collected by those cookies.
                </p>
                  <h4>Controlling my cookies</h4>
                <p>
                  Virtually all modern browsers allow you to see what cookies you've got, and to clear them individually or clear all of them. To find out how to do this, go to aboutcookies.org, which contains comprehensive information on how to do this on a wide variety of desktop browsers.
                </p>
                <h4>How can I choose not to receive Online Behavioural Advertising and other tracking cookies?</h4>
                <p>
                  In addition to the controls available on your computer, there are other ways of choosing not to receive Online Behavioural Advertising and other tracking cookies.
                </p>
                <p>
                  Please note that most of these choices work by setting a cookie that overrides the behavioural advertising cookie. If you clear all your cookies, you will also clear these opt-out cookies, therefore changing your preferences. In this instance you would need to choose again.
                </p>
                <h4>Organisations which provide more information on Online Behavioural Advertising</h4>
                <p>
                  Evidon is a provider of privacy solutions.
                </p>
                <p>
                  Internet Advertising Bureau (IAB) “Your Online Choices” page allows you to choose not to receive behavioural advertising from each of the advertising networks represented by the IAB.
                </p>
                <p>
                  Network Advertising Initiative (NAI) opt out page allows you to choose not to receive behavioural advertising from each of the ad networks represented by the NAI.
                </p>
                <p>
                  Digital Advertising Alliance’s self-regulatory program for online behavioural advertising choices page.
                </p>


              </div>

            </div>

    </Fragment>
  );

}
