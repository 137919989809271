import { ethers } from 'ethers';



// controllers
import { _toaster, _log } from './Helpers'
import { store } from './redux/Store'
import { _apiGet } from './Api'


//
//
let PROVIDER = null;
let SIGNER = null;
if(window.ethereum){
    PROVIDER = new ethers.providers.Web3Provider(window.ethereum);
    SIGNER = PROVIDER.getSigner();
}
const MAX_ALLOWANCE = ethers.BigNumber.from('0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')


const nftABI = [
    "function safeTransferFrom(address from, address to, uint256 tokenId) public",
    "function tokenOfOwnerByIndex(address owner, uint256 index) public view returns (uint256)",
    "function tokenURI(uint256 tokenId) external view returns (string memory)",
    "function balanceOf(address owner) external view returns (uint256 balance)"
];

const dropABI = [
    "function mintToken() public ",
    "function getCounter() external view returns (uint)",
    "function getTokenMaxCap() external view returns (uint)",
    "function getMintEnable() public view returns (bool)"
];

// address
let NFTaddress = ''
let DROPaddress = ''


// Drops
const drops = [
    {
        contract: 'drop',
        title: 'siNgulariFTy 1st edition drop',
        description: 'siNgulariFTy 1st edition drop is a special opening NFT drop by siNgulariFTy platform powered by NextHash Group. You can claim your first limited NFT called siNgulariFTy 1st edition as a welcome present by opening siNgulariFTy platform. Feel free to claim your siNgulariFTy 1st edition NFT now.',
        bgImageClass: 'bg-defaultdrop',
        isAvailable: false,
        totalClaimed: 0,
        totalCapacity: 0,
        author:'Nexthash',
        price:0
    }
]





// 
// 
export const _contract = async (contract) => {

    const session = store.getState();
    
    if(!session.auth){
        return;
    }


    // Binance Samrt Chain
    if(session.auth.response.chainId===56){
        NFTaddress = '0x627da1e4e84f74d2d7dd19c0e1b6b3ffa67189a1'
        DROPaddress = '0xb6f3546123d102c340c0d0d931bb2dd12fa1e838'
    }

    // Rinkeby
    if(session.auth.response.chainId===4){
        NFTaddress = '0x627da1e4e84F74d2D7dD19C0E1B6B3fFA67189a1'
        DROPaddress = '0xb6F3546123d102c340c0D0d931BB2dd12FA1E838'
    }

    if(contract==='nft'){
        return new ethers.Contract(NFTaddress, nftABI, SIGNER)
    }
    if(contract==='drop'){
        return new ethers.Contract(DROPaddress, dropABI, SIGNER)
    }

}


//
//
export const _listNFT = async (address) => {

    try {

        let nftTokens = []
        const contract = await _contract('nft')
        

        for (let index = 0; index < 10000000; index++) {

            try {
                const tokenId = await contract.tokenOfOwnerByIndex(address, index)
                const tokenIPFS = await contract.tokenURI(tokenId._hex)
                const tokenInfo = await _readIPFSinfo(tokenIPFS)
                nftTokens.push({
                    ...tokenInfo,
                    image: tokenInfo.image.replace("ipfs://", "https://ipfs.io/ipfs/"),
                    tokenId: parseInt(tokenId._hex)
                })
            } catch (error){
                break;
            }

        }

        return nftTokens

    } catch (error){
        _log('_listNFT', error, 'error')
        return []
    }

}


// 
// 
export const _readIPFSinfo = async (ipfs) => {

    const token = ipfs.replace("ipfs://", "");
    return await _apiGet(token, false, {}, {baseURL:'https://ipfs.io/ipfs/'})
    .then(res => {
        return res.data
    })
    .catch(err => {
        _log('_readIPFSinfo', [err, token], 'error')
        return false
    })

}


// 
// 
export const _sendNFT = async (tokenId, from, to) => {

    try {

        const contract = await _contract('nft')

        const send = await contract.safeTransferFrom(from, to, tokenId)
        await send.wait()
        // await _timeout(5000)
        
        _toaster('Success', 'NFT token has ben sent succesfully', 'success')
        return true;

    } catch (error){

        _log('_sendNFT', error, 'error')
        _toaster('Error', 'Faild to send NFT token.', 'error')
        return false;

    }

}


//
//
export const _listDropsNFT = async (address) => {

    try {

        let newDrops = []
        const contractNFT = await _contract('nft')

        for (let index = 0; index < drops.length; index++) {
            const drop = drops[index];
            const contract = await _contract(drop.contract)
            const t1 = await contract.getCounter()
            const t2 = await contract.getTokenMaxCap()
            const isEnabled = await contract.getMintEnable()
            const balance = await contractNFT.balanceOf(address)
            newDrops.push({...drop, totalClaimed:parseInt(t1), totalCapacity:parseInt(t2), isAvailable:(isEnabled && parseInt(balance._hex)===0)})
        }

        return newDrops

    } catch (error){
        _log('_listDropsNFT', error, 'error')
        return []
    }

}


// 
// 
export const _claimNFT = async (contractName) => {

    try {

        const contract = await _contract(contractName)
        const claim = await contract.mintToken()
        await claim.wait()
        
        _toaster('Success', 'NFT token claimed succesfully', 'success')
        return true;

    } catch (error){
        _log('_claimNFT', error, 'error')
        return false
    }

}