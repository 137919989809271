import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { Loader } from 'react-feather';

// controllers
import { _metaMaskConnect, _connectionStatus } from '../../controllers/MetaMask'
import { _isActivePage, _copy } from '../../controllers/Helpers'
import { _shortenAddress, _timeout } from '../../controllers/Helpers'
import useWindowSize from '../../controllers/hooks/useWindowSize'

import logo from '../../assets/images/logo.png';


//
//
function NavItem(props){

    const item = props.data
    const location = useLocation()


    //
    //
    const _isActive = (url) => {

        if(_isActivePage(url, location)){ return true } 
        return false
    }


    //
    //
    return (
        <li className={`${item.type} ${_isActive(item.url) ? 'active' : ''}`}>
            {item.type==='link' && <Link to={item.url} onClick={()=>props.onClick ? props.onClick() : null}>{item.icon && item.icon} <span>{item.title}</span></Link>}
        </li>
    )

}


//
//
export default function Header(props) {


    const links = [
        {
            type:'link', 
            url: '/',
            title: 'Home'
        },
        {
            type:'link', 
            url: '/wallet',
            title: 'Wallet'
        },
        {
            type:'link', 
            url: '/drop',
            title: 'Drop'
        },
        {
            type:'link', 
            url: '/tutorial',
            title: 'Tutorial'
        },
    ]

    const windowSize = useWindowSize()
    const nodeMobile = React.useRef(null);
    const connected = useSelector(state => state.auth.response);
    const [ isMobileOpen , setIsMobileOpen ] = React.useState(false);
    const [ isLoading, setLoading ] = React.useState(false)

    //
    //
    const _connect = async (option) => {
        setLoading(true)

        if(option==='metamask'){
            const connect = await _metaMaskConnect()
            if(connect){
                const getStatus = await _connectionStatus(connect.address, connect.provider)
                if(!getStatus.status){
                    setLoading(false)
                }else{
                    setLoading(false)
                }
            }else{
                setLoading(false)
            }
        }
    }


    //
    //
    const handleClickMobile = async(e) => {
        if(windowSize.width<=1024 ){
            if (nodeMobile.current.contains(e.target)) {
                return;
            }
            if(isMobileOpen){
                await _timeout(100)
                setIsMobileOpen(false);
            }
        }
    };


    //
    //
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickMobile);
        return () => {
            document.removeEventListener("mousedown", handleClickMobile);
        };
    });

    React.useEffect(()=>{
        if(isMobileOpen){
          document.body.classList.add('fixed');
        }else{
          document.body.classList.remove('fixed');
        }
    },[isMobileOpen])


    //
    //
    return (
        <Fragment>
            {
                windowSize.width>1024 
                ? 
                <header className='py-7 flex justify-between items-center'>
                    <Link to={'/'} className="w-52">
                        <img src={logo} alt="siNgulariFTy" className=' w-52' />
                    </Link>
                    <ul className="headerNav">
                    {links.map((item,index)=>{
                            return <NavItem data={item} key={index}/>
                        })}
                    </ul>
                    <button type="button" className='button primary outline' onClick={()=> connected.status ? _copy(connected.address) : _connect('metamask')}>
                        {connected.status ? 
                            <><span className='icon-wallet text-xl mr-2'></span>{_shortenAddress(connected.address)}</>
                            :
                            <>{isLoading ? <><Loader size={20} className="animate-spin mr-2"/>Connecting...</> : <><span className='icon-wallet text-xl mr-2'></span>Connect wallet</>}</>
                        }
                    </button>
                </header>
                :
                <header className='py-7 flex justify-between items-center'>
                    <Link to={'/'} className="w-52">
                        <img src={logo} alt="ELIGMA labs" className='w-28' />
                    </Link>
                    <button className={`navicon ${isMobileOpen ? 'active' : ''}`} onClick={()=> {!isMobileOpen && setIsMobileOpen(true)}}><i></i><i></i><i></i></button>
                    <div className={`mobileMenu ${isMobileOpen ? 'active' : ''}`} ref={nodeMobile}>
                        <div>
                            <ul className="headerNav">
                            {links.map((item,index)=>{
                                return <NavItem data={item} key={index} onClick={()=>setIsMobileOpen(false)}/>
                            })}
                            </ul>
                            <button type="button" className='button primary outline mt-10' onClick={()=> connected.status ? _copy(connected.address) : _connect('metamask')}>
                                {connected.status ? 
                                    <><span className='icon-wallet text-xl mr-2'></span>{_shortenAddress(connected.address)}</>
                                    :
                                    <>{isLoading ? <><Loader size={20} className="animate-spin mr-2"/>Connecting...</> : <><span className='icon-wallet text-xl mr-2'></span>Connect wallet</>}</>
                                }
                            </button>
                        </div>
                    </div>
                </header>
            }
        </Fragment>
    );
};