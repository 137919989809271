import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'


// components
import AccordionItem from '../components/AccordionItem'


//
//
export default function PageTutorial(props){

    
    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>Singularifty Platform & Wallet Tutorials | siNgulariFTy</title>
            </Helmet>

                
                <div className="card dark bg-four bg-center">
                    <h2>Singularifty Platform & Wallet Tutorials</h2>
                    <p>
                        Singularifty offers you a step by step guide for the usage of the Singularifty NFT platform, along with all the necessary tools that will make your NFT experience easy and as smooth as possible. 
                        Learn how to use the MetaMarsk, how to claim or transfer tokens and much more. Our customer support is also available any time you might need it.
                    </p>
                    <div className='flex gap-4'>
                        <button type="button" className='button secondary'>Contact Us</button>
                    </div>
                </div>

                <AccordionItem
                    title="What is MetaMask and how to use it?"
                >
                    <p className='font-bold pb-4'>
                        MetaMask is an easy to use tool, which allows users to store and manage account keys, broadcast transactions, send and receive Ethereum-based cryptocurrencies and NFT tokens, 
                        and securely connect to decentralized applications through a compatible web browser or the mobile app's built-in browser.
                    </p>
                    <p className='text-sm pb-4'>
                        To successfully install the tool visit MetaMask.io and select from Android, iOS for mobile application usage or select the Chrome download option for desktop usage. 
                        You can also go directly to the Chrome store, Google Play store, or Apple App Store to download the plug-in.
                    </p>
                    <p className='text-sm pb-4'>
                        To install and connect the MetaMask follow these simple steps:
                    </p>
                    <p className='text-sm'>
                        <ol className=' list-decimal ml-4'>
                            <li className='py-1'>Install MetaMask mobile at MetaMask.io.</li>
                            <li className='py-1'>Open the MetaMask mobile app and select 'Sync or Import' > 'Scan QR code'.</li>
                            <li className='py-1'>Log into your MetaMask extension.</li>
                            <li className='py-1'>Click Settings > Advanced > Sync with Mobile. You'll see a QR code.</li>
                            <li className='py-1'>Scan this QR code with your MetaMask mobile app (see step #2).</li>
                        </ol>
                    </p>
                </AccordionItem>

                <AccordionItem
                    title="How to Claim NFT Token?"
                >
                    <p className='text-sm pb-4'>
                        To claim and receive an NFT token on the Singularifty platform, you need to reserve Nexthash token funds on your wallet account for the gas charge. 
                        Please purchase your Nexinter tokens and transfer them to your wallet account before claiming an NFT airdrop.
                    </p>
                    <p className='text-sm'>
                        Once you have sufficient funds on your wallet account press “Claim NFT”. After that, you need to confirm the transaction and pay the gas fee. When the transaction is confirmed the NFT will be transferred to your wallet.
                    </p>
                </AccordionItem>

                <AccordionItem
                    title="How to make Transfer of NFT Token?"
                >
                    <p className='text-sm pb-4'>
                        In order to transfer your NFT token, go to your wallet page on the Singularifty platform. Choose the specific digital asset you want to transfer and click “Transfer NFT”, enter the Ethereum wallet address that you'd like to transfer your specific NFT to. 
                        Please make sure you’re entering an Ethereum wallet address that can accept deposits of NFTs before continuing. 
                    </p>
                    <p className='text-sm pb-4'>
                        Enter the wallet address that you'd like to transfer the NFT to.  Click the “Continue” button. Your wallet will prompt you to confirm the transaction and pay a gas fee to start the transfer. Confirm the transaction by pressing ‘’Send’’ and your transfer is completed!
                    </p>
                    <p className='text-sm'>
                        You can also track the progress of the transfer on Etherscan. Etherscan is a useful resource to track all transactions on the Ethereum blockchain and it’s free to use.
                    </p>
                </AccordionItem>

                <AccordionItem
                    title="How to make Multi Transfer of NFT Tokens?"
                >
                    <p className='text-sm pb-4'>
                        In order to transfer multiple NFT tokens on the Singularifty platform, go to your wallet page. Choose the specific digital asset you want to transfer and click “Transfer NFT”, enter the Ethereum wallet address that you'd like to transfer your specific NFT to. 
                        Please make sure you’re entering an Ethereum wallet address that can accept deposits of NFTs before continuing. 
                    </p>
                    <p className='text-sm pb-4'>
                        Enter the wallet address that you'd like to transfer your multiple NFT’s to.  Click the “Continue” button. Your wallet will prompt you to confirm the transaction and pay a gas fee to start the transfer. 
                        Confirm the transaction by pressing ‘’Send’’ and your transfer is completed!
                    </p>
                    <p className='text-sm'>
                        You can also track the progress of the transfer on Etherscan. Etherscan is a useful resource to track all transactions on the Ethereum blockchain and it’s free to use.
                    </p>
                </AccordionItem>



        </Fragment>
    );

}
