import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Loader } from 'react-feather';
import { Link } from 'react-router-dom';


// controlers
import { _listDropsNFT, _claimNFT } from '../controllers/SmartContract'
import { _metaMaskConnect, _connectionStatus } from '../controllers/MetaMask'


// components
import Loading from '../components/Loading'
import Modal from '../components/Modal'


//
//
export default function PageDrop(props){
    
    const connected = useSelector(state => state.auth.response);
    const [ isLoading, setLoading ] = React.useState(true)
    const [ isLoading2, setLoading2 ] = React.useState(false)
    const [ dropList, updateDroplist ] = React.useState([])
    const [ modalNFT, setModalNft ] = React.useState(false)


    //
    //
    const _connect = async (option) => {
        setLoading2(true)

        if(option==='metamask'){
            const connect = await _metaMaskConnect()
            if(connect){
                const getStatus = await _connectionStatus(connect.address, connect.provider)
                if(!getStatus.status){
                    setLoading2(false)
                }else{
                    setLoading2(false)
                }
            }else{
                setLoading2(false)
            }
        }
    }


    // 
    // 
    const _claim = async (contract) => {
        setLoading(true)
        await _claimNFT(contract)
        const get = await _listDropsNFT(connected.address)
        updateDroplist(get)
        setLoading(false)
    }


    //
    //
    const _listNFTItems = (total) => {

        console.log(total)

        if(total>0){
            return [...Array(total)].map((item,index)=>{
                return <img src={require('../assets/images/nexthash-nft.png')} alt="" key={index} />
            })
        }
    }


    //
    //
    React.useEffect(()=>{
        const init = async()=>{
            if(connected.status){
                setLoading(true)
                const get = await _listDropsNFT(connected.address)
                updateDroplist(get)
            }
            setLoading(false)
            // setModalSend(false)
        }
        init()
    },[connected])

    
    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>NFT Drops | siNgulariFTy</title>
            </Helmet>


                
                <div className="card dark bg-three">
                    <h2>NFT Drops</h2>
                    <p>
                        This wallet represent your NFT Tokens from your connected wallet address. You can filter your token list by collection, send and recive NFT tokens.
                    </p>
                    {/*connected.status && 
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button secondary'>Discover Drops</button>
                        <button type="button" className='button secondary outline'>All Drops</button>
                    </div>
    */}
                </div>

                {/* 
                    <div className={`card light bg-nodrops bg-center mt-8`} >
                        <h3>NFT drops are currently not available</h3>
                        <p>Please wait for new drops.</p>
                    </div>
                */}

                {!connected.status && 
                    <div className={`card light bg-notconnected bg-center mt-8`} >
                        <h3>Your Wallet is not connected!</h3>
                        <p>To be able to see your NFT Tokens and drops please connect your wallet with Metamask. Please click the button below: Connect Wallet or click the MetaMask Tutorial and follow the instructions.</p>
                        <div className='flex flex-col sm:flex-row gap-4'>
                            <button type="button" className='button primary' onClick={()=> _connect('metamask')}>
                                <>{isLoading2 ? <><Loader size={20} className="animate-spin mr-2"/>Connecting...</> : <>Connect wallet</>}</>
                            </button>
                            <Link to={'/tutorial'} className='button primary outline'>MetaMask Tutorial</Link>
                        </div>
                    </div>
                }

                {
                    connected.status && dropList.length>0 && dropList.map((item,index)=>{
                        return(
                            <div className={`card light ${item.bgImageClass} bg-center mt-8`} key={index}>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <div className='flex flex-col md:flex-row gap-2 mb-8'>
                                    <div className='label light'><span className="icon-star mr-2"></span>Avaliable: {item.totalClaimed}/{item.totalCapacity} NFT</div>
                                    <div className='label light'><span className="icon-tag mr-2"></span>Price: {item.price !== 0 ? item.price : 'FREE'}</div>
                                    {/* <div className='label light'><span className="icon-clock mr-2"></span>Ends at: 2021-12-31 12:00:00 UTC</div> */}
                                </div>
                                <div className='flex flex-col sm:flex-row gap-4'>
                                    <button type="button" className='button primary' disabled={!item.isAvailable} onClick={()=>_claim(item.contract)}>Claim Santa NFT</button>
                                    {(item.totalCapacity-item.totalClaimed)>0 && <button type="button" className='button primary outline' onClick={()=>setModalNft(item)}>List of NFTs</button>}
                                </div>
                            </div>
                        )
                    })
                }


                <Modal
                    status={modalNFT ? true : false}
                    close={()=>setModalNft(false)}
                    size="full"
                    showClose={false}
                >
                    {modalNFT && 
                    <div className='container mx-auto'>
                        <div className='flex flex-col justify-center items-center py-8'>
                            <button type="button" className='button white flext items-center h-14 px-8' onClick={()=>setModalNft(false)}>
                                <img src={require('../assets/images/back.png')} alt="" className='w-4 h-4 mr-2'/>
                                Back to App
                            </button>

                            <div className='grid grid-cols-1 md:grid-cols-3 w-full max-w-xl mx-auto pt-10 pb-4'>
                                <div className='text-white flex flex-col justify-center items-center flex-1 border-b-2 md:border-b-0 md:border-r-2 border-b-secondary md:border-r-secondary py-5 md:py-0'>
                                    <span className='font-semibold opacity-50 pb-2'>Author</span>
                                    <span className='font-bold text-xl'>{modalNFT.author}</span>
                                </div>
                                <div className='text-white flex flex-col justify-center items-center flex-1 border-b-2 md:border-b-0 md:border-r-2 border-b-secondary md:border-r-secondary py-5 md:py-0'>
                                    <span className='font-semibold opacity-50 pb-2'>Collection</span>
                                    <span className='font-bold text-xl'>{modalNFT.author}</span>
                                </div>
                                <div className='text-white flex flex-col justify-center items-center flex-1 py-5 md:py-0'>
                                    <span className='font-semibold opacity-50 pb-2'>Token Count</span>
                                    <span className='font-bold text-xl'>{modalNFT.totalCapacity}</span>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white rounded-xl md:rounded-3xl p-4 md:p-8 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4'>
                            {_listNFTItems(modalNFT.totalCapacity-modalNFT.totalClaimed)}
                        </div>
                    </div>
                    }
                </Modal>


                <Loading status={isLoading}/>



        </Fragment>
    );

}
