import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';

export default function Footer(props) {

    return (
        <Fragment>
            <footer className="my-8 bg-primary px-8 py-10 rounded-3xl grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                <div className='font-sans font-medium text-white text-opacity-70 text-center md:text-left'>Nexthash © 2022</div>
                <ul className="footerNav">
                    <li><Link to={'/cookies/'}>Cookies</Link></li>
                    <li><Link to={'/privacy/'}>Privacy</Link></li>
                    <li><Link to={'/terms/'}>Terms</Link></li>   
                </ul>
                <ul className="footerSocial">
                    <li><a href="/" target="_blank" rel='noreferrer'><i className="icon-twitter"></i></a></li>
                    <li><a href="/" target="_blank" rel='noreferrer'><i className="icon-linkedin"></i></a></li>
                    <li><a href="/" target="_blank" rel='noreferrer'><i className="icon-facebook"></i></a></li>
                </ul> 
            </footer>
        </Fragment>
    );
};