import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Loader } from 'react-feather';
import QRCode from "react-qr-code"


// controlers
import { _listNFT, _sendNFT } from '../controllers/SmartContract'
import { _metaMaskConnect, _connectionStatus } from '../controllers/MetaMask'
import { _toaster, _copy } from '../controllers/Helpers'


// components
import Loading from '../components/Loading'
import NFTitem from '../components/NFTitem'
import Modal from '../components/Modal'


//
//
export default function PageWallet(props){

    const connected = useSelector(state => state.auth.response);
    const [ isLoading, setLoading ] = React.useState(true)
    const [ isLoading2, setLoading2 ] = React.useState(false)
    const [ totalSelected, setTotalSelected ] = React.useState(0)
    const [ ntfList, updateNFTlist ] = React.useState([])

    // modal - send
    const [ modalSend, setModalSend ] = React.useState(false)
    const [ sendToAddress, setSendToAddress ] = React.useState('')

    // modal - no nft selected
    const [ modalNoNft, setModalNoNft ] = React.useState(false)

    // modal - receive
    const [ modalReceive, setModalReceive ] = React.useState(false)


    //
    //
    const _connect = async (option) => {
        setLoading2(true)

        if(option==='metamask'){
            const connect = await _metaMaskConnect()
            if(connect){
                const getStatus = await _connectionStatus(connect.address, connect.provider)
                if(!getStatus.status){
                    setLoading2(false)
                }else{
                    setLoading2(false)
                }
            }else{
                setLoading2(false)
            }
        }
    }


    //
    //
    const _selectNFT = (tokenId) => {
        let newArray = [...ntfList]
        const index = newArray.findIndex(x => x.tokenId===tokenId)
        if(index !== -1){
            newArray[index].selected = !newArray[index].selected
        }
        updateNFTlist(x => newArray)
    }


    //  0x51712aB17F1Af1C1b377C09ad2F4E119C1d5F752
    //  0x14cd7821eC614620Bb6ccb1Dc3011ea072676740
    const sendNFT = async() => {
        
        if(ntfList.filter(x => x.selected).length===0){
            _toaster('Error', 'You must select one of NFT tokens.', 'error')
            setSendToAddress('')
            setModalSend(false)
            setLoading(false)
            return
        }

        setLoading(true)
        const send = await _sendNFT(ntfList.filter(x => x.selected)[0].tokenId, connected.address, sendToAddress)
        if(send){
            const get = await _listNFT(connected.address)
            updateNFTlist(get)
            setSendToAddress('')
            setModalSend(false)
        }
        setLoading(false)
        
    } 


    //
    //
    React.useEffect(()=>{
        const init = async()=>{
            if(connected.status){
                setLoading(true)
                const get = await _listNFT(connected.address)
                updateNFTlist(get)
            }
            setLoading(false)
            setModalSend(false)
        }
        init()
    },[connected])


    //
    //
    React.useEffect(()=>{
        setTotalSelected(ntfList.filter(x => x.selected).length)
    },[ntfList])
    
    
    //
    //
    return (
        <Fragment>
            <Helmet>
                <title>My NFT Wallet | siNgulariFTy</title>
            </Helmet>


                
                <div className="card dark bg-one bg-center">
                    <h2>My NFT Wallet</h2>
                    <p>
                        This wallet represent your NFT Tokens from your connected wallet address. You can filter your token list by collection, send and recive NFT tokens.
                    </p>
                    {!isLoading && connected.status && ntfList.length>0 && 
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button type="button" className='button secondary' onClick={()=>setModalReceive(true)}>Receive NFT</button>
                        <button type="button" className='button secondary' onClick={()=>ntfList.filter(x => x.selected).length===0 ? setModalNoNft(true) : setModalSend(true)}>Send NFTs {totalSelected>0 && '('+totalSelected+')'}</button>
                        {/* <button type="button" className='button secondary outline'>All Collections</button> */}
                    </div>
                    }
                </div>


                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 my-8'>
                    {ntfList.length>0 && ntfList.map((item,index) => {
                        return(
                            <NFTitem
                                key={index}
                                author={'Nexthash'}
                                collection={item.name}
                                tokenId={item.tokenId}
                                image={item.image}
                                selected={item.selected ? true : false}
                                action={_selectNFT}
                            />
                        )
                    })}
                </div>

                {!isLoading && connected.status && ntfList.length===0 && 
                    <div className={`card light bg-emptywallet bg-center -mt-8`} >
                        <h3>Your NFT Wallet is empty</h3>
                        <p>To be able to see your NFT Tokes you need to connect your Metamask wallet. If you do not see NFT tokens please make a purchase and make sure that your Metamask wallet is connected with your account.</p>
                    </div>
                }

                {!connected.status && 
                    <div className={`card light bg-notconnected bg-center -mt-8`} >
                        <h3>Your Wallet is not connected!</h3>
                        <p>To be able to see your NFT Tokens and drops please connect your wallet with Metamask. Please click the button below: Connect Wallet or click the MetaMask Tutorial and follow the instructions.</p>
                        <div className='flex flex-col sm:flex-row gap-4'>
                            <button type="button" className='button primary' onClick={()=> _connect('metamask')}>
                                <>{isLoading2 ? <><Loader size={20} className="animate-spin mr-2"/>Connecting...</> : <>Connect wallet</>}</>
                            </button>
                            <Link to={'/tutorial'} className='button primary outline'>MetaMask Tutorial</Link>
                        </div>
                    </div>
                }

                <Modal
                    status={modalSend}
                    close={()=>setModalSend(false)}
                    size="small"
                    showClose={true}
                >
                    <div className=''>
                        <h4 className='font-serif font-bold text-xl lg:text-2xl mb-4 max-w-xl text-center'>Send NFT token</h4>
                        <p className='mb-6 text-center text-sm'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium, eros sit amet dapibus finibus, felis ligula facilisis massa, eget maximus ipsum velit ut eros. 
                        </p>
                        <div className='mb-6 formGroup'>
                            <label>Address</label>
                            <input type="text" className='formControl' value={sendToAddress} onChange={(e)=>setSendToAddress(e.target.value)} />
                        </div>
                        <button type="button" className='button secondary w-full' onClick={()=>sendNFT()} disabled={sendToAddress==='' ? true : false}>Send NFT</button>
                    </div>
                </Modal>

                <Modal
                    status={modalNoNft}
                    close={()=>setModalNoNft(false)}
                    size="small"
                    showClose={true}
                >
                    <div className=''>
                        <h4 className='font-serif font-bold text-xl lg:text-2xl mb-4 max-w-xl text-center'>Please select NFTs to send first.</h4>
                        <p className='mb-6 text-center text-sm'>
                        To select a NFT click on NFT that you already have in wallet and than tick the circule box to confirm selection . Once you have selected one or more NFTs you can click Send NFTs button in the hero section.
                        </p>
                        <button type="button" className='button secondary w-full' onClick={()=>setModalNoNft(false)}>Close</button>
                    </div>
                </Modal>

                <Modal
                    status={modalReceive}
                    close={()=>setModalReceive(false)}
                    size="medium"
                    showClose={true}
                >
                    <div className=''>
                        <h4 className='font-serif font-bold text-xl lg:text-2xl mb-4 max-w-xl text-center'>Receive NFTs</h4>
                        <p className='mb-6 text-center text-sm'>
                        To receive NFT please deposit your NFT to address bellow or scan QR code. Deposit olny NFTs that are compatible with the chain. All other will be lost.
                        </p>
                        <p className='mb-8 mt-2 flex justify-center items-center'>
                        <QRCode value={connected.address} size={192} fgColor={'#011E41'}/>
                        </p>
                        <div className='mb-6 formGroup'>
                            <input type="text" className='formControl text-center' value={connected.address} readOnly={true} />
                        </div>
                        <button type="button" className='button secondary w-full' onClick={()=>_copy(connected.address, true, 'Address has been copied succesfully')}>Copy Address</button>
                    </div>
                </Modal>


                <Loading status={isLoading}/>


        </Fragment>
    );

}
