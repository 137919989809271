import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom';


export default function Page403(props){


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>403 access denied | siNgulariFTy</title>
      </Helmet>

        <div className="main justify-center landing">

          <div className="container">
            <div className="mx-auto px-8 py-40 w-5/6 md:w-1/2 lg:w-[458px]">

              <h1 className="text-center text-4xl">403</h1>
              <h3 className="h3 text-center">Access denied</h3>
              <p className="text-center mt-5 mb-8">You don't have permission to access requested page.</p>
              
              <div className="text-center">
                <Link to={'/'} className="button primary w-full">Back to the start page</Link>
              </div>

            </div>
          </div>
          
        </div>
        
    </Fragment>
  );

}
