import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async'


export default function Terms(props){


  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>Terms and Conditions | siNgulariFTy</title>
      </Helmet>


            <div className="main justify-center landing">

              <div className="container py-5 typography">

                <h1>Terms and Conditions</h1>
                
                <h4>January 2021</h4>

                <h2>
                1. Acceptance of Terms
                </h2>
                <p>
                Thank you for choosing siNgulariFTy. The following Terms and Conditions and the Data Protection Policy (together, the “Terms”) apply to any person that registers for and/or opens a siNgulariFTy Account through siNgulariFTy.com or any associated mobile applications, website, or APIs (together, the “siNgulariFTy Site”).
                </p>
                <h4>
                The Terms constitute a legally binding agreement between you and each of:
                </h4>
                <p>
                Nexthash Group LTD, a company incorporated and registered under the Laws of United Kingdom, bearing registration number 11691861 and having its registered address at Barkeley Square House, Barkeley Square, London, W1J 6BD, United Kingdom.
                For the purposes of the Terms, any reference to “we” “us” “our” “Nexthash” “siNgulariFTy” and/or any similar term shall be construed as reference to Nexthash Group LTD.
                </p>
                <p>
                By registering for and opening a Nexthash Account, you unconditionally accept these Terms and agree to be bound by and act in accordance with them. You also accept and agree that you are solely responsible for understanding and complying with all laws, rules, regulations and requirements of the jurisdiction in which you live that may be applicable to your use of the siNgulariFTy Site and/or your Nexthash Account, including but not limited to, those related to export or import activity, taxes or foreign currency transactions.
                Please read these Terms carefully before using the siNgulariFTy Site because they affect your legal rights and obligations.
                </p>
                <h2>
                2. Amendment of Terms
                </h2>
                <p>
                Nexthash may amend the Terms from time to time. You should visit the siNgulariFTy website regularly to check when the Terms were last updated (as displayed at the top of this document) and to review the current Terms. We will do our best to notify you of any amendments to the Terms that we consider likely to materially affect your rights and obligations. Any such notice will be posted on the siNgulariFTy Site, or sent by email to the address associated with your siNgulariFTy Account (see Electronic Communications).
                </p>
                <p>
                The continued use of your siNgulariFTy Account after any amendment to these Terms constitutes your acceptance of the Terms as modified by such amendment. If you do not accept the Terms, or any amendment to them, you must immediately stop using the siNgulariFTy Site and your siNgulariFTy Account. Failure to do so will signify your acceptance of the amended Terms.
                </p>
                <h2>
                3. Eligibility
                </h2>
                <p>
                By opening a siNgulariFTy Account, you expressly warrant and represent that:
                </p>
                <p>
                    <ul>
                        <li>You are 18 years of age or over and have full capacity to accept the Terms and enter any transaction available through the siNgulariFTy Site;</li>
                        <li>You will not open, or attempt to open, a siNgulariFTy Account under any name except your own; or use your siNgulariFTy Account to carry out transactions on behalf of a third party;</li>
                        <li>You will follow THE siNgulariFTy BUSINESS ACCOUNT OPENING PROCESS if you seek to open and use a siNgulariFTy Account for a non-individual legal entity (e.g. a company, trust or partnership);</li>
                        <li>You will not have more than one siNgulariFTy Account; use or access any siNgulariFTy Account other than your own; or assist any other person in obtaining unauthorised access to any siNgulariFTy Account;</li>
                        <li>You will provide security for transactions carried out through your siNgulariFTy Account by ensuring there is sufficient Local Currency or cryptocurrency (as the case may be) in your siNgulariFTy Account in advance of any transaction;</li>
                        <li>You will not allow or facilitate the deposit of Local Currency into your siNgulariFTy Account unless that deposit is made in accordance with the Deposit Requirements through external exchange;</li>
                        <li>siNgulariFTy Account does not allow or facilitate the withdrawal of Local Currency from your siNgulariFTy Wallet to a bank account held in the name of a third party. siNgulariFTy Account allow to withdraw cryptocurrencies or NTFs;</li>
                        <li>You will not use your siNgulariFTy Account for or in relation to any illegal activity, any Prohibited Activity in violation of any laws, statutes, ordinances or regulations, or in breach of siNgulariFTy’s Export Controls and Sanctions requirements;</li>
                        <li>By opening a siNgulariFTy Account you accept and agree that siNgulariFTy may, without further notice and in its sole discretion, terminate, suspend or restrict the account of any customer who uses, or who we reasonably suspect may be using, the siNgulariFTy Site or any siNgulariFTy Account in a manner that is inconsistent with the letter or spirit of these Terms.</li>
                    </ul>
                </p>
                <h2>
                4. Risk
                </h2>
                <p>
                Before using the siNgulariFTy Site, you should ensure that you understand the risks involved in buying, selling or trading NFTs and cryptocurrencies. NFT and cryptocurrency markets can be volatile and prices can fluctuate significantly, which could result in sudden and significant increases or decreases in the value of your assets. This market volatility and other risks involved in buying, selling or trading cryptocurrencies are explained on the risk warning of the siNgulariFTy website. There may be additional risks not identified in these Terms or in the risk warning.
                </p>
                <p>
                You should carefully assess whether your financial situation and risk tolerance is suitable for buying, selling or trading NFT or cryptocurrency. You accept and agree that you are solely responsible for any decision to buy, sell, trade or otherwise hold or deal with NFT and cryptocurrency.
                </p>
                <h2>
                5. Electronic Communications
                </h2>
                <h4>
                You accept and agree that:
                </h4>
                <p>
                    <ul>
                        <li>any communications, agreements, notices and/or any other documents (together “Communications”) relating to your siNgulariFTy Account or your use of siNgulariFTy’s services will be provided to you electronically by posting them on the siNgulariFTy Site, emailing them to the email address you have provided to us, or through any other form of electronic communication. You consent to receiving all Communications electronically;</li>
                        <li>you will at all times have available to you the necessary hardware and software to receive, access and retain Communications sent to you electronically, including a device with an internet connection and a valid and accessible email address;</li>
                        <li>you assume full responsibility for providing siNgulariFTy with a valid and accessible email address to which any Communications may be sent, and for ensuring that email address and any other contact information is kept up to date. Any Communication sent to the email address you have provided to us will be deemed to have been received by you. You can amend your contact information by signing-in to your siNgulariFTy Account and accessing the Settings page.</li>
                        <li>You may at any time withdraw your consent to receiving Communications electronically by contacting support@nexthash.com. You acknowledge that failure to give, or withdrawing, consent to receiving Communications electronically puts the security of your siNgulariFTy Account at risk and that siNgulariFTy reserves the right to close your siNgulariFTy Account in the event you fail to give, or withdraw, your consent to the receipt of Communications electronically.</li>
                    </ul>
                </p>
                <h2>
                7.1 siNgulariFTy wallet
                </h2>
                <p>
                The siNgulariFTy Wallet allows you to send, receive and store NFTs (together, “siNgulariFTy Wallet Transactions”).
                </p>
                <p>
                Supported Cryptocurrencies. The siNgulariFTy Wallet is only available in relation to the cryptocurrencies that siNgulariFTy, in its sole discretion, decides to support (“Supported Cryptocurrency” or “Supported Cryptocurrencies”). The Supported Cryptocurrencies may change from time to time. Under no circumstances should you attempt to carry out a siNgulariFTy Wallet Transaction in relation to a cryptocurrency other than a Supported Cryptocurrency. In particular, but without limiting the generality of the foregoing, you accept and agree that you will have no access, entitlement or claim:
                </p>
                <p>
                    <ul>
                        <li>to any cryptocurrency sent to a receive address associated with your siNgulariFTy Wallet where that cryptocurrency is not a Supported Cryptocurrency; or</li>
                        <li>to any cryptocurrency sent to a receive address associated with your siNgulariFTy Wallet, where that receive address is associated with another cryptocurrency. For example, you will have no access, entitlement or claim to any Bitcoin Cash (BCH) sent to a Bitcoin (BTC) receive address.</li>
                        <li>Information about the Supported Cryptocurrencies can be found on the Fees and Features of the siNgulariFTy website.</li>
                        <li>Local Currency are not supported in your siNgulariFTy Wallet, only Supported Cryptocurrencies. Exchange of Local Currency deposited into your siNgulariFTy Wallet will be enabled trough external provider and it is solely for (1) the purchase of Supported Cryptocurrencies with which you will be able to purchase NFT tokens. You accept and agree that any such purchase will be subject to these Terms.</li>
                        <li>Proceeds from the sale of Supported NTFs will be credited to your Cryptocurrency siNgulariFTy Wallet, less any applicable fees (as explained in Fees, and on the Fees and Features page of the siNgulariFTy website). siNgulariFTy does not pay interest on Supported Cryptocurrency balances held in your siNgulariFTy Wallet.</li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </p>
                <p>
                Transaction instructions. siNgulariFTy will process siNgulariFTy Wallet Transactions according to your instructions. You accept and agree that siNgulariFTy does not:
                </p>
                <p>
                    <ul>
                        <li>guarantee the identity of any user, receiver, or other party to a siNgulariFTy Wallet Transaction. You are solely responsible for ensuring all transaction details are correct, and you should carefully verify all transaction information prior to submitting transaction instructions to siNgulariFTy;</li>
                        <li>have any control over, or any liability in relation to, the delivery, quality or any other aspect of any goods or services that you may buy from or sell to any third party. siNgulariFTy shall not be responsible for, and will take no action in relation to, ensuring that any buyer or seller you transact with using your siNgulariFTy Wallet completes the relevant transaction or has the requisite authority to do so.</li>
                        <li>Receiving cryptocurrency. You may receive Supported Cryptocurrency into your siNgulariFTy Wallet by providing the sender with a receive address generated in your siNgulariFTy Wallet. Your siNgulariFTy Wallet will only be credited with Supported Cryptocurrency sent to a receive address generated through your siNgulariFTy Wallet and associated with that Supported Cryptocurrency. For example, your siNgulariFTy Wallet will be credited with ETH when it is sent to an ETH receive address generated through your siNgulariFTy Wallet.</li>
                        <li>Funds received in error. In the event you know, suspect, or should reasonably know or suspect, that any cryptocurrency has been credited to your siNgulariFTy Wallet (or to any bank account) in error, you must immediately notify siNgulariFTy of the error by submitting a ticket through the Help Centre. You accept and agree that you have no claim or entitlement to any cryptocurrency received in error and must immediately return such funds in accordance with the instructions received from siNgulariFTy.</li>
                    </ul>
                </p>
                <h2>
                8. Deposits
                </h2>
                <p>
                Where your identity has been verified (in accordance with the Identity Verification requirements), you may deposit Local Currency into your siNgulariFTy Wallet via third party provider by depositing funds into a Nexthash bank account (a “Deposit”), where the provider will exchange Local Currency into Supported Cryptocurrency.
                </p>
                <p>
                Details of the third-party provider into which you must make the Deposit will be provided to you by third-party provider, and such bank account will be held and operated by third-party provider, a Nexthash Group subsidiary company. You accept and agree that any information you provide to Nexthash Group subsidiary company may be shared with any financial institution, which will process Local Currency transactions in accordance with Nexthash’s instructions.
                </p>
                <p>
                Due to legal and operational constraints, you must strictly adhere to the following Deposit requirements (the “Deposit Requirements”):
                </p>
                <p>
                    <ul>
                        <li>You may only make a Deposit via third-party provider from a bank account held in your own name. You must not allow any third party to make a Deposit into your Nexthash Wallet;</li>
                        <li>You agree to abide by the Deposit Requirements and agree and accept that failure to do so may cause delay in or failure of any Deposit reflecting in your Nexthash Wallet which will be connected to your siNgulariFTy Wallet, and/or restriction, suspension or termination of your Nexthash Account.</li>
                        <li>Allocation of deposits. When you request to make a Deposit through your Nexthash Wallet, you will be provided with a Reference Number or Unique Deposit Amount. To permit timely and successful allocation of a Deposit to your Nexthash Wallet, you must use the Reference Number or Unique Deposit Amount when you make an online bank transfer or use any other Supported Deposit Method. Nexthash will not be responsible for any delay in or non-allocation of Deposits to your Nexthash Wallet which is connected to siNgulariFTy Wallet where you fail to specify the required Reference Number or Unique Deposit Amount.</li>
                    </ul>
                </p>
                <p>
                Deposits may take time to be reflected in your Nexthash Wallet which is connected to siNgulariFTy Wallet due to bank processing times, operational processes, and/or failure by you to adhere to the Deposit Requirements. Under no circumstances will Nexthash have any liability to you in relation to any delay in or failure of a Deposit reflecting in your Nexthash Wallet.
                </p>
                <p>
                Nexthash shall make all reasonable efforts to allocate Deposits that do not meet the Deposit Requirements. However, any Deposits less than the Minimum Unallocated Deposit Amount that cannot be allocated within a one month period will be retained by Nexthash and you accept and agree that you shall have no further claim or entitlement to such funds. The Minimum Unallocated Deposit Amount shall until otherwise determined by Nexthash be EUR50.00 or the equivalent in your Local Currency.
                </p>
                <h2>
                9. Withdrawals
                </h2>
                <p>
                Withdraws from your siNgulariFTy Account, you may withdraw funds from your siNgulariFTy Wallet to your other crypto wallet or/and crypto exchange (a “Withdrawal”). siNgulariFTy will process Withdrawals in accordance with your instructions. You are solely responsible for ensuring that any Withdrawal instructions provided to siNgulariFTy, including the relevant wallet numbers, are complete and correct and you accept and agree that under no circumstances will siNgulariFTy be under any obligation to reverse or amend any Withdrawal.
                </p>
                <p>
                siNgulariFTy will make all reasonable efforts to process Withdrawals each business day, in accordance with the Deposit and Withdrawal Schedule on the siNgulariFTy website, but you accept and agree that siNgulariFTy provides no guarantee in relation to the Withdrawal processing period.
                </p>
                <h2>
                10. Instant Buy and Sell
                </h2>
                <p>
                siNgulariFTy Wallet holders may use the Instant Buy or Sell service to buy or sell a chosen amount of NFTs at the quoted exchange rate.
                </p>
                <p>
                In using the Instant Buy or Sell service, you accept and agree that:
                </p>
                <p>
                    <ul>
                        <li>When you make an Instant Buy or Sell order, siNgulariFTy will match your order with open orders on the siNgulariFTy Platform or other cryptocurrency exchanges available to us. siNgulariFTy will facilitate the trade automaticly, in accordance with your instructions;</li>
                        <li>siNgulariFTy is not acting as your broker, intermediary, agent, adviser or in any fiduciary capacity and no information or communication provided to you by siNgulariFTy in relation to an Instant Buy or Sell transaction will constitute advice;</li>
                        <li>when you place an order to buy or sell NFTs with Supported Cryptocurrency, we will provide you with a quoted exchange rate. siNgulariFTy is unable to, and will not, specify the percentage of the quoted exchange rate that reflects siNgulariFTy’s facilitation of the relevant trade, as it will vary with market conditions. The quoted exchange rate may differ from rates seen elsewhere on the siNgulariFTy site, or on external sources, and you acknowledge that the quoted exchange rate is the rate that will apply to your order;</li>
                        <li>By proceeding with your Instant Buy or Sell transaction, you confirm that you accept the quoted exchange rate, including its components;</li>
                        <li>a volume limit per transaction will apply to any Instant Buy or Sell order; and</li>
                        <li>siNgulariFTy does not guarantee the availability of, or that there will be no delays or interruptions in or to, the Instant Buy or Sell service.</li>
                    </ul>
                </p>
                <p>
                Before confirming any Instant Buy or Sell transaction of NFTs, you must ensure that the amount of Supported Cryptocurrency to be bought or sold is correct and that you agree to the quoted exchange rate. siNgulariFTy cannot, and will not, reverse an executed order.
                </p>
                <p>
                siNgulariFTy reserves the right to delay or refuse any Instant Buy or Sell transaction if, in our sole discretion, we perceive there to be a risk of fraud or illegal activity, or where we have reasonable grounds on which to suspect an error may have been made. siNgulariFTy shall have no liability to you in connection with any delay in or non-completion of an Instant Buy or Sell transaction.
                </p>
                <h2>
                11. The siNgulariFTy Platform
                </h2>
                <p>
                The siNgulariFTy Platform is an NFT order book exchange platform for cryptocurrencies that is intended for use by customers with experience in and/or knowledge of similar platforms. Any person using the siNgulariFTy Platform does so entirely at their own risk.
                </p>
                <p>
                    <ul>
                        <li>All trades executed on the siNgulariFTy Platform are executed automatically, based on the parameters of your order instructions, and cannot be reversed. In the case of technical issues affecting siNgulariFTy’s internal systems, siNgulariFTy may, where possible and in its sole discretion, take steps to reverse or otherwise amend a trade;</li>
                        <li>All trading on the siNgulariFTy Platform is subject to siNgulariFTy’s fee structure, as set out on the Fees and Features page of the siNgulariFTy website. By using the siNgulariFTy Platform, you accept and agree to all applicable fees;</li>
                        <li>siNgulariFTy makes no guarantee that the siNgulariFTy Platform will be available without interruption; that there will be no delays, failures, errors, omissions or loss of transmitted information; or that any order will be executed, accepted, recorded, or remain open.</li>
                        <li>siNgulariFTy may, at any time and in our sole discretion, refuse any trade, impose limits on the trade amount permitted, or impose any other conditions or restrictions upon your use of the siNgulariFTy Platform that we deem necessary. We may, for example, limit the number of open orders that you may establish or restrict trades from certain locations. siNgulariFTy reserves the right to take such action without prior notice.</li>
                    </ul>
                </p>
                <h2>
                12. Fees
                </h2>
                <p>
                The fees applicable to transactions undertaken on the siNgulariFTy Site can be viewed on the Fees and Features page of the siNgulariFTy website. siNgulariFTy reserves the right to change our fees at any time and will update the Fees and Features page accordingly. In certain circumstances, and at our sole discretion, siNgulariFTy may notify selected customers of a specific fee change, where we consider a change to be of particular relevance to such customers.
                </p>
                <p>
                Send and Receive fees. You may be charged a fee to send Supported Cryptocurrency from your siNgulariFTy Wallet and/or to receive Supported Cryptocurrency into your siNgulariFTy Wallet. Fees will apply where Supported Cryptocurrency is sent to or received from a Supported Cryptocurrency address (e.g. a Bitcoin address); no fees will apply where Supported Cryptocurrency is sent to or received from a mobile telephone number or email address. Please see the Fees and Features page of the siNgulariFTy website for further information on send and receive fees.
                </p>
                <p>
                Deposit and Withdrawal fees. All Withdrawals from your siNgulariFTy Wallet will incur a fee. Such fees differ by country and are set out on the Fees and Features page of the siNgulariFTy website. Some Deposits made using a Supported Deposit Method may incur a fee, as set out on the Fees and Features page, and cash Deposits may incur a Cash Deposit Penalty (see Deposits ). Such fees will be displayed in your transaction history upon completion of the transaction.
                </p>
                <p>
                Instant Buy and Sell fees. The quoted exchange rate in relation to any buy or sell order placed using the Instant Buy and Sell service will include an amount to reflect siNgulariFTy’s facilitation of the relevant trade, the percentage of which will vary with market conditions (see Instant Buy or Sell).
                </p>
                <p>
                Trade fees. siNgulariFTy applies a Maker / Taker fee structure for customers using the siNgulariFTy Platform, which is explained in the siNgulariFTy Help Centre. Please consult the Fees and Features page of the siNgulariFTy website for further information on applicable Maker and Taker fees. siNgulariFTy will, at the time of any transaction on the siNgulariFTy Platform, notify you of any fees that will apply to the transaction. By proceeding with any transaction, you accept and agree to the applicable fees. Such fees will also be displayed in your transaction history upon completion of the transaction.
                </p>
                <p>
                siNgulariFTy takes security very seriously and the measures we have in place to protect your siNgulariFTy Account are explained on the Security Page of the siNgulariFTy website. However, you are solely responsible for:
                </p>
                <p>
                    <ul>
                        <li>maintaining adequate security and control over your siNgulariFTy Account sign-in details, including but not limited to any passwords, personal identification numbers (PINs), API keys, or any other codes associated with your siNgulariFTy Account;</li>
                        <li>enabling any additional security features available to you, including the activation of two factor authentication on your siNgulariFTy account;</li>
                        <li>keeping your contact details up to date so that you can receive any notices or alerts we may send to you in relation to security;</li>
                        <li>maintaining security and control over the email mailbox, phone number and two-factor authentication applications or devices associated with your siNgulariFTy Account.</li>
                        <li>Failure to take the above measures, and any other security measures available to you, may result in unauthorised access to your siNgulariFTy Account and the loss or theft of any cryptocurrency and/or Local Currency balances held in your siNgulariFTy Wallet or any linked bank account(s) with third party provider. siNgulariFTy shall have no liability to you for or in connection with any unauthorised access to your siNgulariFTy Account, where such unauthorised access was due to no fault of siNgulariFTy, and/or any failure by you to act upon any notice or alert that we send to you.</li>
                        <li>The security of your siNgulariFTy Account may be compromised, or interruption caused to it, by phishing, spoofing or other attack, computer viruses, spyware, scareware, Trojan horses, worms or other malware that may affect your computer or other equipment. siNgulariFTy strongly recommends that you regularly use reputable virus screening and prevention software and remain alert to the fact that SMS, email services and search engines are vulnerable to spoofing and phishing attacks.</li>
                        <li>Care should be taken in reviewing messages purporting to originate from siNgulariFTy and, should you have any uncertainty regarding the authenticity of any communication, you should log in to your siNgulariFTy Account through the siNgulariFTy website (specifically, www.siNgulariFTy.com) and not any other domain name or website purporting to be, or to be related to, siNgulariFTy) to review any transactions or required actions.</li>
                        <li>To the maximum extent permitted by applicable law, you accept and agree that you have full responsibility for all activity that occurs in or through your siNgulariFTy Account and accept all risks of any unauthorised or authorised access to your siNgulariFTy Account.</li>
                    </ul>
                </p>
                <p>
                Private keys. siNgulariFTy securely stores all Supported Cryptocurrency private keys (“Private Keys”) associated with any siNgulariFTy Account. You accept and agree that siNgulariFTy may retain full ownership and control of the Private Keys associated with your siNgulariFTy Account or assign the said ownership and control to trusted third parties and that you shall have no control of, access to, or the ability to use, such Private Keys. For example, but without limiting the generality of the foregoing, siNgulariFTy will not:
                </p>
                <p>
                    <ul>
                        <li>accept or adhere to any instruction to sign any data with a Private Key;</li>
                        <li>give access to any funds associated with your private keys, other than those of the Supported Cryptocurrency associated with your cryptocurrency wallet;</li>
                        <li>allow the creation of any receive addresses associated with a Private Key other than receive addresses created through your siNgulariFTy Wallet. siNgulariFTy will not credit to your siNgulariFTy Wallet any cryptocurrency associated with a Private Key other than where such funds have been received via a receive address generated through your siNgulariFTy Wallet. However, in exceptional cases, siNgulariFTy reserves its rights to update, correct and/or balance your siNgulariFTy Wallet in case of software errors or failure.</li>
                    </ul>
                </p>
                <h2>
                13. Account Security
                </h2>
                <p>
                siNgulariFTy takes security very seriously and the measures we have in place to protect your siNgulariFTy Account are explained on the Security Page of the siNgulariFTy website. However, you are solely responsible for:
                </p>
                <p>
                    <ul>
                        <li>maintaining adequate security and control over your siNgulariFTy Account sign-in details, including but not limited to any passwords, personal identification numbers (PINs), API keys, or any other codes associated with your siNgulariFTy Account;</li>
                        <li>enabling any additional security features available to you, including the activation of two factor authentication on your siNgulariFTy account;</li>
                        <li>keeping your contact details up to date so that you can receive any notices or alerts we may send to you in relation to security;</li>
                        <li>maintaining security and control over the email mailbox, phone number and two-factor authentication applications or devices associated with your siNgulariFTy Account.</li>
                        <li></li>
                        <li></li>
                    </ul>
                </p>
                <p>
                Failure to take the above measures, and any other security measures available to you, may result in unauthorised access to your siNgulariFTy Account and the loss or theft of any cryptocurrency and/or Local Currency balances held in your siNgulariFTy Wallet or any linked bank account(s) with third party provider. siNgulariFTy shall have no liability to you for or in connection with any unauthorised access to your siNgulariFTy Account, where such unauthorised access was due to no fault of siNgulariFTy, and/or any failure by you to act upon any notice or alert that we send to you.
                </p>
                <p>
                The security of your siNgulariFTy Account may be compromised, or interruption caused to it, by phishing, spoofing or other attack, computer viruses, spyware, scareware, Trojan horses, worms or other malware that may affect your computer or other equipment. siNgulariFTy strongly recommends that you regularly use reputable virus screening and prevention software and remain alert to the fact that SMS, email services and search engines are vulnerable to spoofing and phishing attacks.
                </p>
                <p>
                Care should be taken in reviewing messages purporting to originate from siNgulariFTy and, should you have any uncertainty regarding the authenticity of any communication, you should log in to your siNgulariFTy Account through the siNgulariFTy website (specifically, www.siNgulariFTy.com) and not any other domain name or website purporting to be, or to be related to, siNgulariFTy) to review any transactions or required actions.
                </p>
                <p>
                To the maximum extent permitted by applicable law, you accept and agree that you have full responsibility for all activity that occurs in or through your siNgulariFTy Account and accept all risks of any unauthorised or authorised access to your siNgulariFTy Account.
                </p>
                <p>
                Private keys. siNgulariFTy securely stores all Supported Cryptocurrency private keys (“Private Keys”) associated with any siNgulariFTy Account. You accept and agree that siNgulariFTy may retain full ownership and control of the Private Keys associated with your siNgulariFTy Account or assign the said ownership and control to trusted third parties and that you shall have no control of, access to, or the ability to use, such Private Keys. For example, but without limiting the generality of the foregoing, siNgulariFTy will not:
                </p>
                <p>
                    <ul>
                        <li>accept or adhere to any instruction to sign any data with a Private Key;</li>
                        <li>give access to any funds associated with your private keys, other than those of the Supported Cryptocurrency associated with your cryptocurrency wallet;</li>
                        <li>allow the creation of any receive addresses associated with a Private Key other than receive addresses created through your siNgulariFTy Wallet.</li>
                    </ul>
                </p>
                <p>
                 Nexthash will not credit to your siNgulariFTy Wallet any cryptocurrency associated with a Private Key other than where such funds have been received via a receive address generated through your siNgulariFTy Wallet. However, in exceptional cases, siNgulariFTy reserves its rights to update, correct and/or balance your siNgulariFTy Wallet in case of software errors or failure.
                </p>
                <h2>
                14. Service Availability
                </h2>
                <p>
                While we will do everything we can to provide continuous operations, siNgulariFTy does not provide any warranty in relation to the availability of the siNgulariFTy Site or your siNgulariFTy Account. Without limiting the generality of the foregoing, we do not guarantee continuous access to the siNgulariFTy Site or your siNgulariFTy Account and make no representation that the siNgulariFTy Site, siNgulariFTy API, your siNgulariFTy Account and/or any products or services offered therein will be available without interruption; or that there will be no delays, failures, errors, omissions or loss of transmitted information.
                </p>
                <h2>
                15. Transactions on siNgulariFTy Platform
                </h2>
                <p>
                When you use your siNgulariFTy Account to send or receive cryptocurrency, the transaction must be confirmed and recorded in the public ledger associated with the relevant cryptocurrency network (e.g. the Bitcoin network or the Ethereum network). That cryptocurrency network is solely responsible for verifying and confirming any such transactions. siNgulariFTy cannot confirm, cancel or reverse transactions on a cryptocurrency network, other than confirming to you that the network has completed the transaction. You accept and agree that:
                </p>
                <p>
                Once submitted to a cryptocurrency network, a transaction will be unconfirmed for a period of time pending sufficient confirmation of the transaction by the network. A transaction is not complete while it is in a pending state. Funds associated with transactions that are in a pending state will be designated accordingly, and will not be included in your siNgulariFTy Wallet balance or be available to you to conduct transactions;
                </p>
                <p>
                When you send cryptocurrency from your siNgulariFTy Account, you are authorising us to submit your transaction request to the relevant cryptocurrency network. Once a transaction request has been submitted to the relevant cryptocurrency network, the network will automatically complete or reject the request and neither you or siNgulariFTy will be able to cancel or otherwise modify your transaction.
                </p>
                <p>
                Cryptocurrency networks are operated by decentralised networks of independent third parties. They are not owned, controlled or operated by siNgulariFTy so we cannot ensure that any transaction details you submit will be confirmed by the relevant cryptocurrency network. You agree that any transaction details you submit may not be completed, or may be substantially delayed, by the cryptocurrency network used to process the transaction.
                </p>
                <p>
                Network protocols and operating rules. The underlying software protocols that govern the operation of the Supported Cryptocurrencies are open source. Accordingly, anyone can use, copy, modify, and distribute them and siNgulariFTy has no ownership of or control over these protocols. By using the siNgulariFTy Site, you accept and agree that:
                </p>
                <p>
                    <ul>
                        <li>siNgulariFTy is not responsible for the operation of any cryptocurrency network’s underlying software protocols and makes no guarantee as to their availability, security, or functionality;</li>
                        <li>the underlying software protocols are subject to sudden changes in operating rules (known as “forks”), and that such forks may materially affect the value, function, and/or name of any cryptocurrency you store in your siNgulariFTy Account. Should a fork occur, siNgulariFTy may, with or without notice to you, temporarily suspend our operations and, in our sole discretion, decide whether or not to support either branch of the forked protocol entirely; and</li>
                        <li>in the event siNgulariFTy decides not to support a branch of a forked protocol, you will not be given access to the assets on that fork. Those assets will be securely held by siNgulariFTy and we will not buy or sell them.</li>
                    </ul>
                </p>
                <h2>
                16. Account Inactivity
                </h2>
                <p>
                Local Currency cannot be deposited into your siNgulariFTy Wallet, but siNgulariFTy is offering third party solution for the purchase of Supported Cryptocurrencies and/or Withdrawal to an approved bank account. You must not use your siNgulariFTy Wallet for the purpose of storing Supported  Cryptocurrencies. Should we have reasonable grounds on which to suspect you are using your siNgulariFTy Wallet primarily for the purpose of storing Supported  Cryptocurrencies, siNgulariFTy reserves the right to restrict, suspend or terminate your siNgulariFTy Account.
                </p>
                <p>
                Unclaimed property. In the event any funds are held in your siNgulariFTy Wallet(s) and siNgulariFTy has no record of any use of your siNgulariFTy Account for several years, and we are unable to contact you, the law in your jurisdiction may require us to report and deliver such funds to the relevant authorities as unclaimed property. To the extent permitted by applicable law, siNgulariFTy reserves the right to deduct a dormancy fee or other administrative charges from such unclaimed funds.
                </p>
                <h2>
                17. Closure of your Account
                </h2>
                <p>
                You may close your siNgulariFTy Account by submitting a closure request via the Help Centre. siNgulariFTy will action such requests once (i) the sum of all cryptocurrency balances in your Nexthash Wallet(s) is below the Minimum Closure Amount; and (ii) no transactions have taken place in your siNgulariFTy Account for a period of at least 30 days. Such requirements are designed to protect you from loss and siNgulariFTy will not action a closure request until they are satisfied.
                </p>
                <p>
                You accept and acknowledge that, once your siNgulariFTy Account is closed:
                </p>
                <p>
                    <ul>
                        <li>you will have no further access to it;</li>
                        <li>siNgulariFTy will be under no obligation to notify you of, or provide to you, any cryptocurrency sent to any receive address associated with your siNgulariFTy Account; and</li>
                        <li>siNgulariFTy reserves the right (but has no obligation) to delete all of your information and account data stored on our servers, and also reserves the right to retain any information that is required for legal or operational reasons.</li>
                    </ul>
                </p>
                <p>
                If at the date of closure of your account any cryptocurrency remains in your siNgulariFTy Wallet(s) (i.e. funds amounting to less than the Minimum Closure Amount), you accept and agree that siNgulariFTy shall retain such funds and that you shall have no further claim to them. The Minimum Closure Amount shall be EUR20.00 or the equivalent in your Local Currency converted in cryptocurrency.
                </p>
                <p>
                You accept and agree that siNgulariFTy shall not be liable to you or any third party in relation to the closure of your siNgulariFTy Account, the termination of access to your siNgulariFTy Account, or for the deletion of your information or siNgulariFTy Account data.
                </p>
                <h2>
                18. Restrictions, suspension and termination
                </h2>
                <p>
                siNgulariFTy reserves the right to restrict, suspend or terminate your siNgulariFTy Account where:
                </p>
                <p>
                    <ul>
                        <li>we reasonably suspect your siNgulariFTy Account to be the subject of an operational or other error, in which case we may be required to suspend access to your account until such time as the error is rectified;</li>
                        <li>we reasonably suspect your siNgulariFTy Account has been or is being used in relation to any unlawful, fraudulent or Prohibited Activity, or in breach of these Terms;</li>
                        <li>we reasonably suspect you or your siNgulariFTy Account is or has been associated with, or poses a high risk of, money laundering, financing of terrorism, fraud, or any other financial crime;</li>
                        <li>we reasonably suspect you of taking any action that siNgulariFTy considers to be a circumvention of siNgulariFTy’s controls, including but not limited to opening multiple siNgulariFTy Accounts;</li>
                        <li>we reasonably suspect your involvement in any attempt to gain unauthorised access to any siNgulariFTy Account;</li>
                        <li>your siNgulariFTy Account is or appears to be the subject of any legal, regulatory or government process and/or we, in our sole discretion, consider there to be a heightened risk of legal or regulatory non-compliance associated with your siNgulariFTy Account;</li>
                        <li>we are compelled to do so by a prima facie valid subpoena, court order, or other binding order of a government or regulatory authority; or</li>
                        <li>your name appears on a government or international body sanctions list.</li>
                    </ul>
                </p>
                <p>
                siNgulariFTy will make all reasonable efforts to provide you with notice of any decision to restrict, suspend or terminate your siNgulariFTy Account, unless we are prevented from doing so by any legal or regulatory process or requirement, or where doing so may compromise siNgulariFTy’s security and/or risk management procedures. You accept and agree that siNgulariFTy is under no obligation to disclose to you the fact of or reason for any decision to restrict, suspend or terminate your siNgulariFTy Account, and shall have no liability to you in connection with the restriction, suspension or termination of your siNgulariFTy Account.
                </p>
                <h2>
                19. Use of Nexthash API
                </h2>
                <p>
                siNgulariFTy’s Application Programming Interface (together, the “siNgulariFTy API”) shall soon be introduced as part of siNgulariFTy’ services. Any use of the siNgulariFTy API is subject to this section and, for the avoidance of doubt, the Terms in their entirety.
                </p>
                <p>
                Any use of the siNgulariFTy API as and when it will be introduced is entirely at your own risk. It is your responsibility to keep any API keys, passwords or other credentials relating to your use of the siNgulariFTy API secure, and you accept and agree that you take full responsibility for any and all requests made over the API, whether authorised by you or not.
                </p>
                <p>
                siNgulariFTy sets and enforces limits on your use of the siNgulariFTy API (e.g. limiting the number of requests per second). You accept and agree that any circumvention of, or attempt to circumvent, these limits, or any use of the siNgulariFTy API that may adversely affect other users of the siNgulariFTy Site, may result in siNgulariFTy, at its sole discretion, revoking your API access.
                </p>
                <h2>
                20. Financial Advice – Nothing on siNgulariFTy Site amounts to financial advice.
                </h2>
                <p>
                Under no circumstances does any information contained on the siNgulariFTy Site, or provided to you through your siNgulariFTy Account or by any employee, agent or affiliate of siNgulariFTy, constitute financial, investment or other professional advice.
                </p>
                <p>
                You are solely responsible for any decision to store, buy or sell NFT, and such decision should take into account your risk tolerance and financial circumstances. For more information on the risks involved, see Risks and the Risk Warning on the siNgulariFTy website. You should consult your legal or tax professional in relation to your specific situation.
                </p>
                <h2>
                21. Taxes
                </h2>
                <p>
                You are solely responsible for determining whether, and to what extent, any taxes apply to any transactions you carry out through your siNgulariFTy Account, and for withholding, collecting, reporting and remitting the correct amounts of tax to the appropriate tax authorities.
                </p>
                <h2>
                22. Export Control or Sanctions
                </h2>
                <p>
                Your use of the siNgulariFTy Site is subject to international export controls and economic sanctions requirements. By undertaking any activity on the siNgulariFTy Site or through your siNgulariFTy Account, including but not limited to sending, receiving, buying, selling, storing or trading any NFT, you agree that you will at all times comply with those requirements. In particular, and without any limitation to the generality of the foregoing, you may not open, use, or have access to any siNgulariFTy Account if:
                </p>
                <p>
                you are in or under the control of, or resident of, any country subject to UN Sanctions; and
                you intend to supply any acquired or stored NFT or/and cryptocurrency, or transact with people or businesses operating in any country subject to UN Sanctions.
                </p>
                <h2>
                23. Prohibited Activities
                </h2>
                <p>
                You must not use your siNgulariFTy Account to undertake any of the activities or categories of activity set out in this section (each a “Prohibited Activity”):
                </p>
                <p>
                    <ul>
                        <li>violation of any laws, statutes, ordinance or regulations;</li>
                        <li>undertaking, facilitating or supporting criminal activity of any kind, including but not limited to, money laundering, terrorist financing, illegal gambling operations or malicious hacking;</li>
                    </ul>
                </p>
                <p>abusive activity, including but not limited to:</p>
                <p>
                <ul>
                        <li>imposing an unreasonable or disproportionately large load on siNgulariFTy’s infrastructure, or otherwise taking any action that may negatively affect the performance of the siNgulariFTy Site or siNgulariFTy’s reputation;</li>
                        <li>attempting to gain unauthorised access to the siNgulariFTy Site or any siNgulariFTy Account;</li>
                        <li>transmitting or uploading any material to the siNgulariFTy Site that contains viruses, Trojan horses, worms, or any other harmful programmes; or</li>
                        <li>transferring your siNgulariFTy Account access or rights to your siNgulariFTy Account to a third party, unless as required by law or with siNgulariFTy’s prior consent.</li>
                        <li>paying in to or otherwise supporting pyramid schemes, Ponzi schemes, matrix programmes, “get rich quick” schemes, multi-level marketing programmes or high- yield investment programmes;</li>
                        <li>fraudulent activity, including but not limited to taking any actions that defraud siNgulariFTy or a siNgulariFTy customer, or the provision of any false, inaccurate, or misleading information to siNgulariFTy;</li>
                        <li>transactions involving items that may help facilitate or enable illegal activity; promote or facilitate hate, violence or racial intolerance; are considered obscene; or may be stolen goods or the proceeds of crime;</li>
                        <li>transactions involving TOR markets, online gambling sites or mixers;</li>
                        <li>sale or purchase of narcotics or controlled substances;sale or purchase of narcotics or controlled substances;</li>
                    </ul>
                </p>
                <h4>intellectual property infringement.</h4>
                <p>
                By opening a siNgulariFTy Account, you confirm that you will not use your account to undertake any of the above-listed Prohibited Activities or any similar or related activity
                </p>
                <p>
                Activities subject to the prior written approval of siNgulariFTy. Unless you have obtained the prior written approval of siNgulariFTy, you accept and agree that you will not use your siNgulariFTy Account to conduct or operate any of the following business activities or categories of activity:
                </p>
                <p>
                    <ul>
                        <li>money services, including but not limited to money or cryptocurrency transmission, currency or cryptocurrency exchange or dealing, payment service providers, e-money or any other financial services business;</li>
                        <li>gambling or gaming services;</li>
                        <li>charitable or religious / spiritual organisations;</li>
                        <li>consumer lending services, including but not limited to secured and unsecured loans, cash advances, payday lending;</li>
                        <li>investment funds, asset management, or brokerage services.</li>
                    </ul>
                </p>
                <p>
                We reserve the right to restrict, suspend or terminate your siNgulariFTy Account if we suspect, in our sole discretion, that you are using, or have used, your siNgulariFTy Account in association with any of the activities listed above, or any similar or related activity, without having obtained the prior written approval of siNgulariFTy.
                </p>
                <h2>
                24. Disclaimer of Warrantiess
                </h2>
                <p>
                The siNgulariFTy Site, your siNgulariFTy Account and any related products or services are offered on a strictly “as-is” and “where-available” basis and siNgulariFTy expressly disclaims, and you waive, all warranties of any kind, whether express or implied. Without limiting the generality of the foregoing, the siNgulariFTy Site, your siNgulariFTy Account, and any related products or services are offered without any warranty as to merchantability or fitness for any particular purpose.
                </p>
                <p>
                Some jurisdictions do not allow the disclaimer of implied terms in consumer contracts, so some or all of the disclaimers in this section may not apply to you.
                </p>
                <h2>
                25. Limitation of Liability
                </h2>
                <p>
                In no event shall siNgulariFTy, its operating entities or any other affiliates (including their respective directors, members, employees or agents) be liable to you for any direct, indirect, special, consequential, exemplary or punitive damages or any other damages of any kind, including but not limited to loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, whether in contract, tort or otherwise, arising out of or in any way connected with your use of, inability to use, or unavailability of the siNgulariFTy Site and/or your siNgulariFTy Account, including without limitation any damages caused by or resulting from any reliance upon any information received from siNgulariFTy, or that result from mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission or any failure of performance, whether or not resulting from a force majeure event, communications failure, theft, destruction or unauthorised access to siNgulariFTy’s records, programmes or services.
                </p>
                <p>
                In no event will any liability of siNgulariFTy, its operating entities or any other affiliates (including their respective directors, members, employees or agents) arising in relation to your use of the siNgulariFTy Site or your siNgulariFTy Account, exceed (in aggregate) the fees earned by siNgulariFTy in connection with your use of your siNgulariFTy Account in the six month period immediately preceding the event giving rise to the claim for liability.
                </p>
                <p>
                The above limitations of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction. Because some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages, some of the limitations in this section may not apply to you.
                </p>
                <h2>
                26. Indemnity
                </h2>
                <p>
                To the maximum extent permitted by law, you agree to indemnify siNgulariFTy, its operating entities or any other affiliates (including their respective directors, members, employees or agents) against any action, liability, cost, claim, loss, damage, proceeding or expense suffered or incurred directly or indirectly arising from your use of or conduct in relation to the siNgulariFTy Site and/or your siNgulariFTy Account, or from your violation of these Terms.
                </p>
                <h2>
                27. Disputes
                </h2>
                <p>
                You and we agree to notify the other party in writing of any claim or dispute that arises in relation to the Nexthash Site, your siNgulariFTy Account or these Terms, within 30 days of such claim or dispute arising. You and we further agree to attempt informal resolution of any Dispute prior to bringing a claim in any court or other body.
                </p>
                <p>
                Governing law and jurisdiction. This agreement shall be governed by and construed in accordance with United Kingdom law, subject to any local mandatory rights you may have. You and we agree to submit all disputes, claims or controversies (including non-contractual Disputes, claims or controversies) arising out of or in connection with these Terms, or the breach, termination, enforcement or interpretation thereof (together, Disputes), to the non-exclusive jurisdiction of the courts of United Kingdom.
                </p>
                <p>
                Class or representative action waiver. To the maximum extent permissible by law, you and siNgulariFTy each agree that each may bring any Dispute against the other only in your or its individual capacity, and you and it waive any right to commence or participate in any class action or other representative action or proceeding against the other. Further, where permissible by law, unless both you and siNgulariFTy agree otherwise, no court may consolidate any other person’s claim(s) with your Dispute, and may not otherwise preside over any form of representative or class proceeding.
                </p>
                <p>
                For the avoidance of doubt, if this Class or representative action waiver is found by any court of competent jurisdiction to be invalid, void or unenforceable, the remainder of this Disputes clause shall remain valid and enforceable.
                </p>
                <h2>
                28. Entire Agreement
                </h2>
                <p>
                Entire agreement. These Terms constitute the entire agreement and understanding between you and siNgulariFTy with respect to their subject matter and supersede any and all prior discussions, agreements and understandings of any kind between you and siNgulariFTy (including but not limited to any prior versions of these Terms).
                </p>
                <p>
                Severability. If any provision of these Terms, as amended from time to time, is determined to be illegal, invalid or unenforceable, in whole or in part, under any law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the legality, validity and enforceability of the other provisions in these Terms shall not be affected and everything else in these Terms will continue in full force and effect.
                </p>
                <p>
                In the event any provision or part thereof of these Terms is determined to be illegal, invalid or unenforceable, that provision or part thereof shall be replaced by the parties with a legal, valid and enforceable provision or part thereof that has, to the greatest extent possible, a similar effect as the illegal, invalid or unenforceable provision, given the content and purpose of these Terms.
                </p>
                <p>
                Assignment. You may not assign or transfer any of your rights or obligations under these Terms without siNgulariFTy’s prior written approval. You give siNgulariFTy your approval to assign or transfer these Terms in whole or in part, including but not limited to: (i) a subsidiary or affiliate; (ii) an acquirer of siNgulariFTy’s equity, business or assets; or (iii) a successor by merger.
                </p>
                <p>
                Change of control. In the event that siNgulariFTy is acquired by or merged with a third party, we reserve the right to transfer or assign the information we have collected from you as part of such merger, acquisition, sale, or other change of control.
                </p>
                <p>
                Force Majeure. Nexthash shall not be liable for any delays, failure in performance or interruption of service which result directly or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any delay or failure due to any act of God, act of civil or military authorities, act of terrorists, civil disturbance, war, strike or other labour dispute, fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software, other catastrophe or any other occurrence which is beyond our reasonable control.
                </p>
                <p>
                Survival. All provisions of these Terms that by their nature extend beyond the expiry or termination of these Terms, including but not limited to, sections relating to the suspension or termination of your siNgulariFTy Account, use of the siNgulariFTy Site, disputes with siNgulariFTy and general provisions, shall survive the termination of these Terms.
                </p>
                <p>
                Headings. Section headings in these Terms are for convenience only and shall not govern the meaning or interpretation of any provision of these Terms.
                </p>
                <p>
                English language controls. Notwithstanding any other provision of these Terms, any translation is provided solely for your convenience. The meanings of terms, conditions and representations herein are subject to definitions and interpretation in the English language. Any translation provided may not accurately represent the information in the original English
                </p>


              </div>

            </div>

    </Fragment>
  );

}
