import Axios from 'axios';
// import {store} from './redux/Store'


// 
// 
const _cancelToken = () => {
  return Axios.CancelToken.source()
}


const axiosInit = (headers={}, config={}, cancelToken=null, isProtected=false) => {

  let headersConfig = {
      // 'Cache-Control': 'no-cache, no-store, must-revalidate',
      // 'Pragma': 'no-cache',
      // 'Expires': 0,
      // 'Content-Type': 'application/json',
      ...headers
  };

  // if(isProtected){
  //     const session = store.getState();

  //     if(!session.auth.payload?.token){
  //         return _logout()
  //     }

  //     headersConfig = { 
  //         ...headersConfig,
  //         'Authorization': 'Bearer '+session.auth.payload.token
  //     }
  // }
  
  let axiosConfig = {
      baseURL: process.env.NEXT_PUBLIC_API,
      headers: headersConfig,
      cancelToken: cancelToken?.token,
      // withCredentials: true,
      // validateStatus: function (status) {
      //     return status >= 200 && status <= 302
      // },
      ...config
  }
  
  return Axios.create(axiosConfig);

}


//
// 
const _apiGet = async(path, isProtected=false, headers={}, config={}) => {
  return await axiosInit(headers, config, _cancelToken(), isProtected).get(path)
}


//
// 
const _apiPost = async(path, data={}, isProtected=false, headers={}, config={}) => {
  return await axiosInit(headers, config, _cancelToken(), isProtected).post(path, data)
}


//
// 
const _apiPut = async(path, data={}, isProtected=false, headers={}, config={}) => {
  return await axiosInit(headers, config, _cancelToken(), isProtected).put(path, data)
}



// 
// 
export {
  axiosInit,
  _apiGet,
  _apiPost,
  _apiPut,
}